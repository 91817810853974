@use '../../partials/fonts' as *;
@use '../../partials/mixins' as *;
@use '../../partials/globals' as *;
@use '../../partials/variables' as *;

.home {
    //Home Container
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 8rem;
    margin-top: 8rem;
    position: relative;
    height: 600px;
}

.home .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.hero {
    // Contains Hey Im Nicolas and Role
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;

    @include phone {
        flex-direction: column;
        align-items: center;
    }

    &__portrait {
        width: 10.5rem;
        height: 10.5rem;
        border-radius: $border;
        margin-bottom: 20px;
        z-index: 2;
        position: absolute;
        transform: translate(-190%, -15%);
        object-fit: cover;

        @include tablet {
            transform: translate(-250%, 0); 
            width: 8rem; 
            height: 8rem;
            object-fit: cover;
        }
        @include phone {
            display: none;
        }
    }
}

.section__title--hero {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    align-items: flex-start;
    font-weight: $fw-reg;
    color: $clr-light;
    margin-bottom: .5rem;
    margin-right: .5rem;
}

.section__title--name {
    font-size: 2rem;
    font-weight: $fw-bold;
    color: $clr-light;
}

.section__subtitle--hero {
    display: flex;
    color: silver;
    padding: 5px 10px;
    width: 31.5rem;
    flex-direction: column;
    align-items: flex-end;
    font-size: 1.5rem;
    font-weight: $fw-bold;
    background: $clr-accent;
    color: $clr-light;
    letter-spacing: 2px;
    @include tablet {
        width: 27rem;
    }
    @include phone {
        font-size: 1.2rem;
        width: auto;
    }
}

strong {
    font-weight: $fw-bold;
}



.skills {
    background-color: $clr-dark;
    width: 100vw;
    padding-top: $sect-gap;
    padding-bottom: $sect-gap;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;


    &__container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 25rem;
        gap: 1rem;
        @include tablet {
            max-width: 100%;
        }
    }

    &__item {
        width: 5rem;
        height: 7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include tablet {
            width: auto;
        }
    }

    &__image {
        width: 4rem;
        height: 4rem;
    }

    &__title {
        font-size: $fs-body;
        text-align: center;
        color: $clr-light;
        font-weight: 500;
    }
}

.section__title--skills {
    font-size: $fs-h2;
    color: $clr-accent;
    padding-bottom: 1rem;
    position: relative;
}

.section__title--skills::after {
    content: '';
    display: block;
    width: 2em;
    height: 1px;
    margin: .5rem auto;
    background: lightblue;
    opacity: 0.25;
}

.btn {
    padding: 1.5em 5em;
    background: $clr-dark;
    border: none;
    border-radius: $border;
    color: #444;
    font-size: 1rem;
    font-weight: $fw-bold;
    letter-spacing: .2rem;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: .2s ease-in-out;
    color: $clr-accent;
    box-shadow:
        10px 10px 20px #383838,
        -10px -10px 20px #4c4c4c;
    @include tablet {
        padding: 1em 4em;
    }
    @include phone {
        padding: 1em 3em;
    }
}

.btn:hover {
    box-shadow: -2px -2px 6px #4c4c4c,
        -2px -2px 4px #4c4c4c,
        2px 2px 2px #383838,
        2px 2px 4px #383838;
}

.btn:active {
    box-shadow: inset -2px -2px 6px #4c4c4c,
        inset -2px -2px 4px #4c4c4c,
        inset 2px 2px 2px #383838,
        inset 2px 2px 4px #383838;
}