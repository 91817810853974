@use '../../partials/fonts' as *;
@use '../../partials/mixins' as *;
@use '../../partials/globals' as *;
@use '../../partials/variables' as *;

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    color: $clr-light;
    z-index: 5000;

    &__nav {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $clr-nav-background;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 5000;
        transition: transform 0.3s ease-in-out;
        transform: translateX(-100%);
    }

    &__logo {
        height: 40px;
    }
}

.header__nav.active {
    transform: translateX(0);
}

.header__nav a {
    color: $clr-light;
    text-decoration: none;
    padding: 1rem;
    font-size: $fs-h3;
    font-weight: $fw-bold;
}

.header__nav a:hover {
    color: $clr-accent;
}
.header__btn:hover svg {
    fill: $clr-accent;
}

.header__btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.8rem;
    display: block;
}

.header__btn svg {
    fill: $clr-light;
}