@use '../../partials/fonts' as *;
@use '../../partials/mixins' as *;
@use '../../partials/globals' as *;
@use '../../partials/variables' as *;

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100; 
    }
.modal-content {
    background: $clr-background;
    padding: 1.25rem;
    border-radius: 10px;
    width: 40rem; 
    min-height: 30%; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto; 
    z-index: 101; 
    display: flex;
    flex-direction: column;
    justify-content: space-between; 

    &__layout {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%; 
    }

    &__text {
        text-align: left;
        width: 100%; 
    }

    &__button {
        align-self: center; 
        margin-top: 20px; 
            padding: 1.5em 5em;
            background: $clr-background;
            border: none;
            border-radius: $border;
            color: #444;
            font-size: 1rem;
            font-weight: $fw-bold;
            letter-spacing: .2rem;
            text-align: center;
            outline: none;
            cursor: pointer;
            transition: .2s ease-in-out;
            color: $clr-accent;
            box-shadow: 
                8px 8px 16px  #383838,
                -8px -8px 16px #4c4c4c;
            
        }
        &__button:hover {
            box-shadow: -2px -2px 6px #4c4c4c,
                -2px -2px 4px #4c4c4c,
                2px 2px 2px #383838,
                2px 2px 4px #383838;
        }
    
        &__button:active {
            box-shadow: inset -2px -2px 6px #4c4c4c,
                inset -2px -2px 4px #4c4c4c,
                inset 2px 2px 2px #383838,
                inset 2px 2px 4px #383838;
        }
}