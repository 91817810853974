@use '../../partials/fonts' as *;
@use '../../partials/mixins' as *;
@use '../../partials/globals' as *;
@use '../../partials/variables' as *;

.education {
    margin-top: $sect-gap;
    margin-bottom: $sect-gap;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__item {
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: center; 
        text-align: center; 
        width: 100%;
        cursor: pointer;
    }

    &__layout {
        margin-top: 1rem;
        display: flex;
        gap: 1rem;
    }

    &__logo {
        height: 100px;
        width: 100px;
        display: flex;
        object-fit: contain;
    }

    &__name {
        text-align: center;
        margin-top: .5rem;
        font-size: $fs-h3;
    }
    &__modal-name {
        font-size: $fs-h3;
    }
    &__modal-detail {
        font-weight: 500;
        margin-top: .5rem;
    }
    &__modal-item {
        margin-top: .5rem;
        list-style-type: none;
        margin-left: 1rem;
        display: flex;
        align-items: flex-end;
    }
    &__modal-progress {
        width: 18px;
        height: 18px;
        margin-left: .5rem;
    }
}

.section__title--education {
    color: $clr-accent;
    font-size: $fs-h2;
}
.section__title--Ceducation {
    color: $clr-accent;
    font-size: $fs-h3;
    margin-top: 2rem;
}
