@use '/src/partials/fonts' as *;
@use '/src/partials/mixins' as *;
@use '/src/partials/globals' as *;
@use '/src/partials/variables' as *;

* {
  margin: 0;
  padding: 0;
  user-select: none;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

@include phone {
  .page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content-wrap {
    flex: 1;
  }  
}
