@use '../../partials/fonts' as *;
@use '../../partials/mixins' as *;
@use '../../partials/globals' as *;
@use '../../partials/variables' as *;

.projects {
    background-color: $clr-dark;
    width: 100vw;
    padding: 2rem 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: none;

    @include phone {
        padding-left: .75rem;
        padding-right: .75rem;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        max-width: 800px;
        flex: 0 1 calc(33% - 1rem);
        margin-top: 1rem;

        @include tablet {
            flex: 0 1 calc(50% - 1rem);
        }

        @include phone {
            flex: 0 1 100%;
        }
    }

    &__logo {
        width: 450px;
        height: 300px;
        object-fit: cover;
        @include tablet {
            width: 350px;
            height: 200px;
        }
        @include phone {
            width: 70%;
            height: 200px;
        }
    }

    &__layout {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;


        @include tablet {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;
        }
    }

    &__name {
        text-align: center;
        margin-top: .5rem;
        color: $clr-light;
        font-size: $fs-h3;
    }

    &__modal-name {
        font-size: $fs-h3;
        color: $clr-light;
        margin-bottom: .5rem;
    }

    &__modal-detail {
        margin-top: .5rem;
        margin-left: 1rem;
    }

    &__modal-items {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        gap: 1rem;
    }

    &__modal-item {
        list-style-type: none;
        width: 50px;
        height: 50px;
        
    }

    &__modal-item:hover {
        transition: all .2s ease-in-out;
        transform: scale(1.25);
    }
    &__modal-item--icon {
        width: 50px;
        height: 50px;
        
    }
    &__modal-progress {
        width: 18px;
        height: 18px;
        margin-left: .5rem;
    }
}

.section__title--education {
    color: $clr-accent;
    font-size: $fs-h2;
}


.section__title--projects {
    color: $clr-accent;
}