@use '../../partials/fonts' as *;
@use '../../partials/mixins' as *;
@use '../../partials/globals' as *;
@use '../../partials/variables' as *;

.about {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto;
    margin-top: $sect-gap;
    gap: 2rem;
    @include tablet {
        flex-direction: column;
    }

    &__layout {
        display: flex;
        flex-direction: column;
        max-width: 20rem;
    }

    &__img {
        width: auto;
        height: 350px;
        border-radius: $border;
        flex-shrink: 0;
    }

    &__body {
        font-size: $fs-h3;
        font-weight: $fw-reg;
    }
}

.section__title--about {
    font-size: $fs-h2;
    color: $clr-accent;
}

.section__subtitle--about {
    font-size: 2rem;
    color: $clr-light;
    @include phone {
        font-size: 1.5rem;
    }
}