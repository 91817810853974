@use 'variables' as *;

@mixin tablet {
    @media screen and (max-width: $breakpoint-tablet) {
        @content;
    }
}

@mixin phone {
    @media screen and (max-width: $breakpoint-phone) {
        @content;
    }
}