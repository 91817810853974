@use '../../partials/fonts' as *;
@use '../../partials/mixins' as *;
@use '../../partials/globals' as *;
@use '../../partials/variables' as *;

.footer {
    background-color: $clr-dark;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    &__link {
        color: $clr-accent;
        font-size: $fs-h3;
        font-weight: $fw-bold;
    }
}

.social-list {
    display: flex;
    margin-top: 1rem;
    gap: 1rem;
    &__link {
        width: 30px;
        height: 30px;
    }
}