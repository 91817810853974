@use './variables' as *;
@use './mixins' as *;
@use './fonts' as *;

* {
    font-family: 'Montserrat', serif;
    font-size: $fs-body;
    color: $clr-light;
}

a {
    text-decoration: none;
}
