@font-face {
    font-family: 'Montserrat';
    src: url('../../src/typography/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
    font-style: italic;
    font-weight: 100 900;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../src/typography/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-style: normal;
    font-weight: 100 900;
}
