$fw-reg: 300;
$fw-bold: 800;
$clr-light: #fff;
$clr-dark: #424242;
$clr-accent: #1E90FF;
$clr-background:#424242;
$clr-nav-background: #303030;

$fs-h1: 3rem;
$fs-h2: 2.25rem;
$fs-h3: 1.25rem;
$fs-body: 1rem;
$border : 10px;
$sect-gap: 3rem;

// // Breakpoints
$breakpoint-tablet: 768px;
$breakpoint-phone: 425px;